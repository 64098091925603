import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

import { paymentProviderTypes } from '@/types/paymentMethods.types';
import type { PaymentProviderType } from '@/types/paymentMethods.types';

type State = {
  retailerId: string | null;
  retailerName: string | null;
  companyName: string | null;
  logo: string | null;
  retailerTerms: string | null;
  retailerDelivery: string | null;
  retailerFooter: string | null;
  retailerSummaryText: string | null;
  paymentProvider: PaymentProviderType | null;
  primaryColor: string | null;
  secondaryColor: string | null;
  ref: string | null;
  redirectUrl?: string;
  termsUrl?: string;
  isRetailerInitialised: boolean;
};

const getInitialState = (): State => ({
  retailerId: null,
  ref: null,
  retailerName: null,
  companyName: null,
  logo: null,
  retailerTerms: null,
  retailerDelivery: null,
  retailerFooter: null,
  retailerSummaryText: null,
  paymentProvider: null,
  primaryColor: null,
  secondaryColor: null,
  redirectUrl: undefined,
  termsUrl: undefined,
  isRetailerInitialised: false,
});

export const useRetailerStore = defineStore('retailerStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    setData(retailer) {
      const mainStore = useMainStore();
      const paymentProvider =
        import.meta.env.VITE_PAYMENT_PROVIDER_OVERRIDE && !window.Cypress
          ? import.meta.env.VITE_PAYMENT_PROVIDER_OVERRIDE
          : retailer.payment_provider;

      console.debug(`Initialising retailer: ${paymentProvider}`);

      this.retailerId = retailer.retailer_id;
      this.ref = retailer.ref;
      this.retailerName = retailer.name;
      this.companyName = retailer.company_name;
      this.logo = retailer.logo;
      this.retailerTerms = retailer.terms;
      this.termsUrl = retailer.terms_url;
      this.retailerDelivery = retailer.delivery;
      this.retailerFooter = retailer.footer_text;
      this.retailerSummaryText = retailer.free_delivery_text;
      this.paymentProvider = paymentProvider;
      this.primaryColor = retailer.checkout_theme?.primary_colour || '#324876';
      this.secondaryColor = retailer.checkout_theme?.secondary_colour || 'blue';

      // Only supplied in case of 404 on Product lookup
      this.redirectUrl = retailer.redirect_url;

      // Err if Payment Provider not set as expected
      if (!this.paymentProvider || !paymentProviderTypes.includes(this.paymentProvider)) {
        throw new Error(`Payment provider ${this.paymentProvider} is not set in retailer data!`);
      }

      this.isRetailerInitialised = true;

      mainStore.setPaymentProviderDependentData();
    },
  },
});
