<template>
  <header
    class="header-container tw-flex tw-relative tw-flex-row tw-justify-end min-h-0"
    v-if="logo"
  >
    <div
      class="header-branding tw-w-full tw-flex tw-justify-between tw-items-center"
      :style="`background: ${primaryColor}`"
    >
      <Logo />

      <p class="tw-flex tw-flex-col tw-text-right tw-text-white tw-font-light tw-m-0">
        <span class="tw-uppercase tw-opacity-90 tw-text-[10px]"
          >Sold and Dispatched from
        </span>
        <span class="tw-text-base tw-whitespace-nowrap">{{ companyName }}</span>
      </p>
    </div>
  </header>
</template>

<script>
import { mapState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';
import Logo from '@/components/Logo/index.vue';

export default {
  name: 'HeaderPDP',
  components: {
    Logo,
  },
  computed: {
    ...mapState(useRetailerStore, ['companyName', 'primaryColor', 'logo']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
