<template>
  <div v-if="isRenderComponent">
    <button
      id="applepay-btn"
      class="apple-pay-button apple-pay-button-black"
      data-test="apple-pay-button"
      @click="isPaymentPopup ? startApplePay() : startPaymentPopup('applepay')"
      :disabled="isLoading"
    ></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useRetailerStore } from '@/stores/RetailerStore';
import { useCartStore } from '@/stores/CartStore.ts';
import eventTracker from '@/helpers/eventTracker.ts';
import { Utils } from '@/helpers/utils';
import constants from '@/constants';
import { BraintreePaymentAvailability } from '@/helpers/braintreePayments';
import { ApplePaymentError } from '@/types/errors.types';

let applePayInstance;

export default {
  name: 'BraintreeApplePay',
  data() {
    return {
      isLoading: false,
      actions: null,
      shippingContact: null,
      isRenderComponent: true,
      applePaySession: null,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency', 'isPaymentPopup']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useRetailerStore, ['retailerName', 'paymentProvider']),
    ...mapState(useCartStore, ['cart', 'getTotalCartPrice', 'getTotalPaymentPrice']),
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'braintree',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'ApplePaySession',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.initBraintree();
  },
  methods: {
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placeBraintreeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useMainStore, [
      'setPaymentButtonInitialised',
      'onLaunchPaymentPopup',
      'goToErrorPage',
    ]),

    onPaymentError(error) {
      const errorFn = () => {
        this.goToErrorPage({
          error,
        });
      };

      if (this.applePaySession?.abort) {
        this.applePaySession.abort();
        errorFn();
      } else {
        errorFn();
      }
    },

    getLineItems(shippingMethod) {
      const rtn = [];

      rtn.push({
        type: 'final',
        label: 'Subtotal',
        amount: (this.cart.pricing.totalUnitPrice || 0).toString(),
      });

      rtn.push({
        type: 'final',
        label: 'Discount',
        amount: (this.cart.pricing.totalDiscount || 0).toString(),
      });

      if (shippingMethod) {
        rtn.push({
          type: 'final',
          label: `Shipping (${shippingMethod.label})`,
          amount: (this.cart.pricing.shippingPrice || 0).toString(),
        });
      }

      return rtn;
    },

    async initBraintree() {
      try {
        const braintreeClientInstance = await window.braintree.client.create({
          authorization: this.paymentConfig.paymentProviderToken,
        });

        if (!this.isPaymentPopup) {
          if (
            !BraintreePaymentAvailability.isBraintreePaymentAvailable(
              braintreeClientInstance,
              'applePayWeb',
            ) ||
            !this.getIsPaymentMethodEnabled('applepay')
          ) {
            this.setPaymentButtonInitialised('applepay', 'NOT_ENABLED');
            this.isRenderComponent = false;
            return;
          }
        }

        await new Promise((resolve, reject) => {
          console.debug('Creating Apple Pay instance...');

          try {
            // Create apple pay instance
            window.braintree.applePay.create(
              {
                client: braintreeClientInstance,
                useDeferredClient: true,
              },
              (applePayErr, instance) => {
                if (applePayErr) {
                  console.error('Error creating applePayInstance:', applePayErr);
                  return reject(applePayErr);
                }

                applePayInstance = instance;

                this.setPaymentButtonInitialised('applepay', 'INITIALISED');

                resolve(true);
              },
            );
          } catch (err) {
            console.error('Failed to create Apple Pay instance!', err);
            throw err;
          }
        });
      } catch (err) {
        throw new ApplePaymentError('Error in initBraintree()!', err, this.paymentProvider);
      }
    },

    startPaymentPopup(paymentMethod) {
      this.onLaunchPaymentPopup([paymentMethod]);
    },

    async startApplePay() {
      try {
        this.isLoading = true;

        eventTracker.trackEvent({
          event: 'payment_type_selected',
          data: { paymentType: 'applepay' },
        });

        await this.initApplePay();

        this.isLoading = false;
      } catch (err) {
        throw new ApplePaymentError('Error in startApplePay()!', err, this.paymentProvider);
      }
    },

    async initApplePay() {
      console.debug('Initialising Apple Pay instance...');

      try {
        const libVersion = 3;
        const createPaymentRequestProps = {
          currencyCode: this.currency.currencyCode,
          countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
          total: {
            label: this.retailerName,
            amount: this.getTotalPaymentPrice,
          },
          lineItems: this.getLineItems(),
          requiredBillingContactFields: ['postalAddress'],
          requiredShippingContactFields: ['phone', 'email', 'postalAddress'],
          shippingMethods: [],
        };

        const paymentRequest =
          await applePayInstance.createPaymentRequest(createPaymentRequestProps);

        this.applePaySession = new window.ApplePaySession(libVersion, paymentRequest);

        //
        this.applePaySession.onvalidatemerchant = (e) => {
          console.debug('Validating merchant at url:', e.validationURL);

          try {
            applePayInstance.performValidation(
              {
                validationURL: e.validationURL,
                displayName: 'Shopthru Checkout',
              },
              (err, merchantSession) => {
                if (err) {
                  console.error('applePayInstance.performValidation() failed!', err);

                  return this.onPaymentError(
                    new ApplePaymentError(
                      'Error in onvalidatemerchant()!',
                      err,
                      this.paymentProvider,
                    ),
                  );
                }

                this.applePaySession.completeMerchantValidation(merchantSession);
              },
            );
          } catch (err) {
            this.onPaymentError(
              new ApplePaymentError(
                'Error occurred in onvalidatemerchant()!',
                err,
                this.paymentProvider,
              ),
            );
          }
        };

        //
        this.applePaySession.onshippingcontactselected = async ({ shippingContact }) => {
          console.debug('Shipping contact was selected...');

          try {
            const applePayShippingContactUpdate = {};

            this.shippingContact = shippingContact;

            const address = {
              streetAddress: [''],
              city: this.shippingContact.locality,
              region: this.shippingContact.administrativeArea,
              postalCode: this.shippingContact.postalCode,
              country: this.shippingContact.countryCode.toUpperCase(),
            };

            if (
              !constants.ALLOWED_SHIPPING_COUNTRY_CODES.includes(this.shippingContact.countryCode)
            ) {
              return this.applePaySession.completeShippingContactSelection({
                newTotal: {
                  label: this.retailerName,
                  amount: (this.getTotalPaymentPrice || 0).toString(),
                },
                status: window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT,
                errors: [
                  new window.ApplePayError(
                    'shippingContactInvalid',
                    'countryCode',
                    'We cannot ship to the selected country.',
                  ),
                ],
              });
            }

            this.setAddress('billing', address);
            this.setAddress('shipping', address);

            await this.fetchShippingMethods();

            const availableShippingMethods = [];

            (this.shippingMethods || []).forEach((option) => {
              availableShippingMethods.push({
                identifier: option.code,
                label: option.label,
                detail: '',
                amount: option.amount,
              });
            });

            applePayShippingContactUpdate.newShippingMethods = availableShippingMethods;

            // Update Shipping code to selected code
            if (!availableShippingMethods.length) {
              throw new Error('No available shipping methods found!');
            }

            await this.setShippingMethod(availableShippingMethods[0].identifier);

            applePayShippingContactUpdate.newTotal = {
              label: this.retailerName,
              amount: (this.getTotalPaymentPrice || 0).toString(),
            };

            applePayShippingContactUpdate.newLineItems = this.getLineItems();

            this.applePaySession.completeShippingContactSelection(applePayShippingContactUpdate);
          } catch (err) {
            console.error('Failed to update on shipping contact selection!', err);
            throw err;
          }
        };

        //
        this.applePaySession.onshippingmethodselected = async ({ shippingMethod }) => {
          console.debug('Shipping method was selected...');

          try {
            const applePayShippingMethodUpdate = {};

            await this.setShippingMethod(shippingMethod.identifier);

            if (this.shippingContact) {
              // Update Total
              applePayShippingMethodUpdate.newTotal = {
                label: this.retailerName,
                amount: (this.getTotalPaymentPrice || 0).toString(),
              };

              // Update Totals
              applePayShippingMethodUpdate.newLineItems = this.getLineItems(shippingMethod);

              this.applePaySession.completeShippingMethodSelection(applePayShippingMethodUpdate);
            } else {
              this.applePaySession.completeShippingMethodSelection({});
            }
          } catch (err) {
            console.error('Failed to update on shipping method selection!', err);
            throw err;
          }
        };

        //
        this.applePaySession.onpaymentauthorized = ({ payment }) => {
          console.debug('Payment was authorised...');

          try {
            applePayInstance.tokenize(
              {
                token: payment.token,
              },
              (tokenizeErr, payload) => {
                if (tokenizeErr) {
                  console.error('Error in onpaymentauthorized callback!', tokenizeErr);
                  return;
                }

                this.paymentAuthorized({
                  error: null,
                  payment,
                  payload,
                });
              },
            );
          } catch (err) {
            console.error('Failed to update on payment authorisation!', err);
            throw err;
          }
        };

        //
        this.applePaySession.oncancel = (e) => {
          console.log('Apple Pay Session was cancelled.', e);
        };

        this.applePaySession.begin();
      } catch (err) {
        console.error('Failed to initialise Apple Pay instance!', err);
        throw err;
      }
    },

    /**
     * Update details and place order
     */
    async paymentAuthorized({ payment, payload }) {
      try {
        const { billingContact, shippingContact } = payment;

        this.handlePaymentAuthorizedPayload({
          billingContact,
          shippingContact,
        });

        this.setBraintreePaymentData({
          nonce: payload.nonce,
          method: 'applepay',
        });

        this.applePaySession.completePayment(this.applePaySession.STATUS_SUCCESS);

        this.completeOrder();
      } catch (err) {
        throw new ApplePaymentError('Error in paymentAuthorized()!', err, this.paymentProvider);
      }
    },

    completeOrder() {
      try {
        this.placeBraintreeOrder();

        eventTracker.trackEvent({ event: 'payment_complete', data: { paymentType: 'ApplePay' } });
      } catch (err) {
        throw new ApplePaymentError('Error in completeOrder()!', err, this.paymentProvider);
      }
    },

    handlePaymentAuthorizedPayload({ shippingContact, billingContact }) {
      try {
        const formattedShippingAddress = {
          streetAddress: shippingContact.addressLines,
          city: shippingContact.locality,
          postalCode: shippingContact.postalCode,
          region: shippingContact.administrativeArea,
          country: shippingContact.countryCode.toUpperCase(),
        };
        const formattedBillingAddress = {
          streetAddress: billingContact.addressLines,
          city: billingContact.locality,
          postalCode: billingContact.postalCode,
          region: billingContact.administrativeArea,
          country: billingContact.countryCode.toUpperCase(),
          email: shippingContact.emailAddress,
        };

        this.setCustomer('shipping', {
          firstName: shippingContact.givenName,
          lastName: shippingContact.familyName,
          phone: shippingContact.phoneNumber,
          email: shippingContact.emailAddress,
        });

        this.setCustomer('billing', {
          firstName: billingContact.givenName,
          lastName: billingContact.familyName,
        });

        this.setAddress('shipping', formattedShippingAddress);
        this.setAddress('billing', formattedBillingAddress);
      } catch (err) {
        throw new ApplePaymentError(
          'Error in handlePaymentAuthorizedPayload()!',
          err,
          this.paymentProvider,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
