<template>
  <dl id="terms-tab" class="terms-tab tab-panel" :class="isTabOpen ? 'tab-open' : 'tab-closed'">
    <dt
      class="tab-trigger"
      tabindex="0"
      role="button"
      @keydown.enter="toggleTab()"
      @click="toggleTab()"
    >
      <svg
        style="left: 2px"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.40605 17.5008C4.97993 17.5008 4.73436 17.2553 4.73436 16.8291V3.8918C4.73978 3.51442 4.99618 3.25802 5.37355 3.25802C5.62815 3.25802 7.04918 3.25802 7.04918 3.25802H16.3301C16.35 3.25802 16.3698 3.25802 16.3897 3.25802C16.749 3.27247 17 3.53068 17 3.88819C17 4.42266 17 4.95712 17 5.49159V16.8237C17 16.8779 17 16.9411 16.9892 17.0079C16.9332 17.3022 16.6822 17.5008 16.3644 17.5008H5.40425H5.40605ZM15.774 4.48766H5.96399V16.273H15.774V4.48766Z"
          fill="#55575E"
        />
        <path
          d="M0.678917 14.3446C0.240149 14.3446 0 14.1026 0 13.6621V1.15988C0 0.827648 0.175146 0.587499 0.467658 0.51708C0.529049 0.500829 0.590441 0.499023 0.642804 0.499023H11.6319C11.9876 0.499023 12.2602 0.7482 12.2638 1.07682C12.2674 1.4542 12.2674 1.83158 12.2674 2.21437V2.8969H11.0468V1.72685H1.22963V13.1168H4.37143V14.3446H0.678917Z"
          fill="#55575E"
        />
        <path
          d="M3.00998 9.2166C2.82581 9.2166 2.65789 9.14979 2.54052 9.03062C2.42857 8.91867 2.36899 8.76519 2.36899 8.60088C2.37079 8.25601 2.64525 7.99419 3.00637 7.99419H4.07531C4.09517 7.99419 4.11683 7.99419 4.14031 7.996L4.37504 8.00683V9.20035L4.14211 9.21299C4.11683 9.21299 4.09336 9.2166 4.06808 9.2166H3.00818H3.00998Z"
          fill="#55575E"
        />
        <path
          d="M3.01901 11.5856C2.82942 11.5856 2.65789 11.5188 2.53871 11.3978C2.42857 11.2859 2.36718 11.1342 2.36718 10.9699C2.36718 10.6178 2.63983 10.3632 3.0136 10.3632H4.37323V11.5711L4.1367 11.582C4.10961 11.582 4.08253 11.5856 4.05725 11.5856H3.01721H3.01901Z"
          fill="#55575E"
        />
        <path
          d="M3.01179 6.84762C2.63983 6.84762 2.36899 6.58941 2.36899 6.23731C2.36899 5.88341 2.63983 5.62701 3.0136 5.62521H4.37685V6.82956L4.14753 6.844C4.12045 6.84581 4.09697 6.84762 4.07169 6.84762H3.01179Z"
          fill="#55575E"
        />
        <path
          d="M3.51014 4.48044C3.32777 4.48044 3.1436 4.48044 2.96123 4.47863C2.70122 4.47502 2.48093 4.31071 2.39788 4.05972C2.31482 3.80513 2.39968 3.53429 2.61094 3.38622C2.68858 3.33206 2.80775 3.26705 2.94137 3.26344C3.13999 3.25983 3.34041 3.25802 3.54084 3.25802H4.37504V4.44252L4.18906 4.4696C4.15295 4.47682 4.13128 4.47863 4.10781 4.47863H3.51014V4.48044Z"
          fill="#55575E"
        />
        <path
          d="M7.72629 7.63668C7.50961 7.63668 7.3128 7.53195 7.19724 7.3568C7.0871 7.18888 7.06904 6.97582 7.15029 6.78803C7.25502 6.54608 7.46447 6.41246 7.73893 6.41246H14.3746C14.7069 6.41246 14.9506 6.59844 15.0138 6.89456C15.0535 7.07693 15.0084 7.26472 14.8946 7.40736C14.7809 7.55001 14.6075 7.63126 14.4216 7.63306C14.2302 7.63306 14.0388 7.63306 13.8456 7.63306H7.72448L7.72629 7.63668Z"
          fill="#55575E"
        />
        <path
          d="M7.74976 12.3746C7.7299 12.3746 7.70823 12.3746 7.68837 12.3746C7.34891 12.3548 7.09793 12.0876 7.10335 11.7535C7.10876 11.4122 7.37238 11.1558 7.71545 11.154C8.15422 11.154 9.68901 11.154 9.68901 11.154H14.4017C14.6057 11.154 14.7899 11.2425 14.9073 11.3942C15.021 11.5423 15.0589 11.7336 15.012 11.9214C14.9416 12.1977 14.6996 12.3765 14.3999 12.3765C14.2428 12.3765 14.0875 12.3765 13.9304 12.3765H7.75337L7.74976 12.3746Z"
          fill="#55575E"
        />
        <path
          d="M7.72809 10.0057C7.51322 10.0057 7.31641 9.90274 7.20085 9.7294C7.0889 9.56328 7.06904 9.35202 7.14668 9.16243C7.24418 8.92409 7.46447 8.78325 7.73532 8.78325H11.3177C11.6896 8.78325 12.0616 8.78325 12.4336 8.78325C12.6448 8.78325 12.838 8.88437 12.9536 9.0541C13.0655 9.21841 13.089 9.42967 13.015 9.61745C12.9193 9.85941 12.6954 10.0039 12.4173 10.0039H8.77355C8.42507 10.0039 8.07658 10.0039 7.7299 10.0039L7.72809 10.0057Z"
          fill="#55575E"
        />
        <path
          d="M11.7944 14.7418H10.2903C9.45428 14.7418 8.61646 14.7418 7.78046 14.7418C7.67393 14.7418 7.58364 14.7292 7.50239 14.7003C7.22432 14.6046 7.06182 14.3265 7.11057 14.0232C7.15571 13.7343 7.40489 13.523 7.70282 13.5212C8.04769 13.5212 8.39437 13.5194 8.73925 13.5194H12.4209C12.7766 13.5194 13.0421 13.765 13.0547 14.1026C13.0601 14.2742 13.0023 14.4331 12.8904 14.5504C12.7748 14.6714 12.6141 14.74 12.4336 14.7418C12.3487 14.7418 12.2638 14.7436 12.179 14.7436H11.7962L11.7944 14.7418Z"
          fill="#55575E"
        />
      </svg>

      <h4>Terms &amp; Conditions</h4>

      <IconArrow />
    </dt>
    <dd class="tab-content">
      <p>{{ retailerTerms }}</p>
    </dd>
  </dl>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';
import { useMainStore } from '@/stores/MainStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
  name: 'TermsTab',
  components: { IconArrow },
  computed: {
    ...mapState(useRetailerStore, ['retailerTerms']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'TERMS_AND_CONDITIONS';
    },
  },
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'TERMS_AND_CONDITIONS') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'TERMS_AND_CONDITIONS';
      }
    },
  },
};
</script>
