import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

type State = {
  publisherId: string | null,
  publisherEmail: string | null,
  publisherName: string | null,
  publisherUrl: string | null,
  publisherLogo: string | null,
  showPublisherLogoOnLoad: boolean,
};

export const usePublisherStore = defineStore('publisherStore', {
  state: (): State => {
    return {
      publisherId: null,
      publisherEmail: null,
      publisherName: null,
      publisherUrl: null,
      publisherLogo: null,
      showPublisherLogoOnLoad: false,
    };
  },
  actions: {
    setData(publisher) {
      // Set Is dev
      const mainStore = useMainStore();

      this.publisherId = publisher.publisher_id;
      this.publisherEmail = publisher.email;
      this.publisherName = publisher.name;
      this.publisherLogo = publisher.logo_url;
      this.showPublisherLogoOnLoad = publisher.show_logo_on_load;
      this.publisherUrl = mainStore.parentLocation?.href!;
    },
  },
});
