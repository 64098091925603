// @param string formattedPrice - Supply as e.g. £123456.00
const splitFormattedPrice = (
  formattedPrice: string,
): {
  currency: string;
  whole: string;
  remainder: string;
} => {
  const currency = formattedPrice[0];
  const rawWhole = formattedPrice.slice(1, formattedPrice.indexOf('.'));
  const remainder = formattedPrice.split('.')[1];

  // Add commas to the whole number part
  const formattedWhole = parseInt(rawWhole, 10).toLocaleString('en-GB');

  return {
    currency,
    whole: formattedWhole,
    remainder,
  };
};

const isTextContainsHTML = (text: string) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');

  return Array.from(doc.body.childNodes).some(
    (node) => node.nodeType === 1 && node.nodeName !== 'PARSERERROR',
  );
};

export default {
  splitFormattedPrice,
  isTextContainsHTML,
};
