<template>
  <dl class="information-tab tab-panel" :class="isTabOpen ? 'tab-open' : 'tab-closed'">
    <dt
      class="tab-trigger"
      tabindex="0"
      role="button"
      @keydown.enter="toggleTab()"
      @click="toggleTab()"
    >
      <h4>How it Works</h4>

      <IconArrow />
    </dt>
    <dd class="tab-content">
      <p>
        By purchasing from this checkout you are placing your order directly with the retailer, you will receive your order confirmation from them and it will be fulfilled in exactly the same way as if you had purchased directly from their website. The publishing website accepts no responsibility for the processing of the payment or order. Any enquires about the order should be directed to the retailer.
      </p>
      <span>Affiliate Disclaimer</span>
      <p>
        This checkout is powered by Shopthru. Shopthru enables retailers to present their checkout and payment solutions securely to consumers on third-party websites. As part of the Shopthru program, the third-party website may earn a commission from qualifying purchases.
      </p>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';
import { useMainStore } from '@/stores/MainStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
  name: 'InformationTab',
  components: { IconArrow },
  computed: {
    ...mapState(useRetailerStore, ['retailerDelivery']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_INFO';
    },
  },
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_INFO') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_INFO';
      }
    },
  },
};
</script>
