<template>
  <div
    v-if="isRenderComponent"
    ref="googlePayContainer"
    id="google-pay-container"
    style="height: 45px"
  ></div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore';
import { useCartStore } from '@/stores/CartStore';
import { useRetailerStore } from '@/stores/RetailerStore';
import { BraintreePaymentAvailability } from '@/helpers/braintreePayments';
import eventTracker from '@/helpers/eventTracker.ts';
import { GooglePaymentError } from '@/types/errors.types';
import constants from '@/constants';
import { Utils } from '@/helpers/utils';

export default {
  name: 'BraintreeGooglePay',
  props: {
    googleApi: { type: Object, required: false }, // Can be overriden in tests
    braintreeApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      googlePaymentInstance: null,
      isRenderComponent: true,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['cart', 'getTotalPaymentPrice']),
    ...mapState(useRetailerStore, ['paymentProvider']),
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'google',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'braintree',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.init();
  },
  methods: {
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placeBraintreeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),

    getDisplayItems(isIncludeShipping) {
      const rtn = [];

      rtn.push({
        label: 'Subtotal',
        type: 'SUBTOTAL',
        price: (this.cart.pricing.totalUnitPrice || 0).toString(),
        status: 'FINAL',
      });

      rtn.push({
        label: 'Discount',
        type: 'LINE_ITEM',
        price: (this.cart.pricing.totalDiscount || 0).toString(),
        status: 'FINAL',
      });

      if (isIncludeShipping) {
        rtn.push({
          label: 'Shipping',
          type: 'LINE_ITEM',
          price: (this.cart.pricing.shippingPrice || 0).toString(),
          status: 'FINAL',
        });
      }

      return rtn;
    },

    onPaymentError(error) {
      const errorFn = () => {
        this.goToErrorPage({
          error,
        });
      };

      if (this.googlePaymentInstance?.teardown) {
        return this.googlePaymentInstance.teardown(errorFn);
      } else {
        errorFn();
      }
    },

    async init() {
      const googleApi = this.googleApi || window.google;
      const braintreeApi = this.braintreeApi || window.braintree;
      const storeCurrency = this.currency;

      try {
        const thisVue = this;
        const googlePayContainer = this.$refs.googlePayContainer;

        if (!googlePayContainer) return; // TODO neeeded?

        const paymentsClientProps = {
          environment: this.paymentConfig.environment === 'production' ? 'PRODUCTION' : 'TEST',
          paymentDataCallbacks: {
            onPaymentDataChanged: async (intermediatePaymentData) => {
              try {
                const result = await this.onPaymentDataChanged(intermediatePaymentData);
                return result;
              } catch (err) {
                return this.onPaymentError(
                  new GooglePaymentError(
                    'Error in onPaymentDataChanged()!',
                    err,
                    this.paymentProvider,
                  ),
                );
              }
            },
            onPaymentAuthorized: async () => {
              try {
                const result = await this.onPaymentAuthorized();
                return result;
              } catch (err) {
                return this.onPaymentError(
                  new GooglePaymentError(
                    'Error in onPaymentAuthorized()!',
                    err,
                    this.paymentProvider,
                  ),
                );
              }
            },
          },
        };

        let paymentsClient;

        if (window.Cypress) {
          paymentsClient = googleApi.payments.api.PaymentsClient;
          paymentsClient.initStubs(paymentsClientProps);
        } else {
          paymentsClient = new googleApi.payments.api.PaymentsClient(paymentsClientProps);
        }

        const button = paymentsClient.createButton({
          buttonColor: 'black',
          buttonType: 'buy',
          buttonSizeMode: 'fill',
          buttonRadius: 100,
          onClick: async (event) => {
            event.preventDefault();

            eventTracker.trackEvent({
              event: 'payment_type_selected',
              data: { paymentType: 'googlepay' },
            });

            // This second instance will include all price changes and discount just in time
            const paymentDataRequest = await createInitialPaymentDataRequest();

            this.onSubmit({
              paymentsClient,
              paymentDataRequest,
            });
          },
        });

        const braintreeApiClient = await braintreeApi.client.create({
          authorization: this.paymentConfig.paymentProviderToken,
        });

        if (
          !BraintreePaymentAvailability.isBraintreePaymentAvailable(
            braintreeApiClient,
            'androidPay',
          ) ||
          !this.getIsPaymentMethodEnabled('googlepay')
        ) {
          this.setPaymentButtonInitialised('googlepay', 'NOT_ENABLED');
          this.isRenderComponent = false;
          return;
        }

        const googlePaymentInstance = await braintreeApi.googlePayment.create({
          client: braintreeApiClient,
          useDeferredClient: true,
          googlePayVersion: 2,
        });

        thisVue.googlePaymentInstance = googlePaymentInstance;

        const createInitialPaymentDataRequest = async () =>
          googlePaymentInstance.createPaymentDataRequest({
            transactionInfo: {
              displayItems: this.getDisplayItems(),
              countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
              currencyCode: storeCurrency.currencyCode,
              totalPriceStatus: 'ESTIMATED',
              totalPrice: (this.getTotalPaymentPrice || 0).toString(),
              totalPriceLabel: 'Total',
            },
            shippingAddressRequired: true,
            shippingOptionRequired: true,
            shippingAddressParameters: {
              allowedCountryCodes: constants.ALLOWED_SHIPPING_COUNTRY_CODES,
            },
            emailRequired: true,
            callbackIntents: ['PAYMENT_AUTHORIZATION', 'SHIPPING_ADDRESS', 'SHIPPING_OPTION'],
          });

        // This first instance allows us to get the isReadyToPay() response
        const paymentDataRequest = await createInitialPaymentDataRequest();

        const isReadyToPayResponse = await paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
          existingPaymentMethodRequired: true, // Optional
        });

        googlePayContainer.appendChild(button);


        if (isReadyToPayResponse.result) {
          this.setPaymentButtonInitialised('googlepay', 'INITIALISED');
        }
      } catch (err) {
        throw new GooglePaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    async onPaymentDataChanged(intermediatePaymentData) {
      try {
        const storeCurrency = this.currency;

        let paymentDataRequestUpdate = {};
        let newShippingOptions = [];
        let shippingOptionData = intermediatePaymentData.shippingOptionData;

        let address = {
          streetAddress: [''],
          city: intermediatePaymentData.shippingAddress.locality,
          region: intermediatePaymentData.shippingAddress.administrativeArea,
          postalCode: intermediatePaymentData.shippingAddress.postalCode,
          country: intermediatePaymentData.shippingAddress.countryCode,
        };

        this.setAddress('billing', address);
        this.setAddress('shipping', address);

        await this.fetchShippingMethods();

        (this.shippingMethods || []).forEach((method) => {
          newShippingOptions.push({
            id: method.code,
            label: `${method.label} (${method.amount} ${storeCurrency.currencyCode})`,
            description: '',
          });
        });

        if (newShippingOptions.length) {
          const currentShipping =
            shippingOptionData.id === 'shipping_option_unselected'
              ? newShippingOptions[0].id
              : shippingOptionData.id;

          // Set shipping methods in google modal
          paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: currentShipping,
            shippingOptions: newShippingOptions,
          };

          // Update Shipping code to selected code
          await this.setShippingMethod(currentShipping);
        } else {
          paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: 'shipping_option_unselected',
            shippingOptions: [
              {
                id: 'shipping_option_unselected',
                label: 'No shipping available',
                description: '',
              },
            ],
          };

          paymentDataRequestUpdate.error = {
            reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
            message: 'No shipping methods are available for the selected address',
            intent: 'SHIPPING_ADDRESS',
          };
        }

        paymentDataRequestUpdate.newTransactionInfo = {
          displayItems: this.getDisplayItems(true),
          countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
          currencyCode: storeCurrency.currencyCode,
          totalPriceStatus: 'FINAL',
          totalPrice: (this.getTotalPaymentPrice || 0).toString(),
          totalPriceLabel: 'Total',
        };

        return paymentDataRequestUpdate;
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentDataChanged()!', err, this.paymentProvider);
      }
    },

    async onPaymentAuthorized() {
      try {
        return Promise.resolve({ transactionState: 'SUCCESS' });
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentAuthorized()!', err, this.paymentProvider);
      }
    },

    // @see https://developers.google.com/pay/api/web/reference/response-objects#PaymentData
    handleGooglePayPayload(data) {
      try {
        const shipping = data.shippingAddress;
        const billing = data.paymentMethodData.info.billingAddress;

        const shippingAddress = {
          streetAddress: [shipping.address1, shipping.address2 ?? '', shipping.address3 ?? ''],
          city: shipping.locality,
          postalCode: shipping.postalCode,
          region: shipping.administrativeArea,
          country: shipping.countryCode,
        };

        const billingAddress = {
          streetAddress: [billing.address1, billing.address2 ?? '', billing.address3 ?? ''],
          city: billing.locality,
          postalCode: billing.postalCode,
          region: billing.administrativeArea,
          country: billing.countryCode,
        };

        this.setCustomer('shipping', {
          firstName: shipping.name,
          lastName: shipping.name,
        });

        this.setCustomer('billing', {
          firstName: billing.name,
          lastName: billing.name,
          phone: billing.phoneNumber,
          email: data.email,
        });

        this.setAddress('shipping', shippingAddress);
        this.setAddress('billing', billingAddress);
      } catch (err) {
        throw new GooglePaymentError(
          'Error in handleGooglePayPayload()!',
          err,
          this.paymentProvider,
        );
      }
    },

    async onSubmit({ paymentDataRequest, paymentsClient }) {
      try {
        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with all
        // Google Pay card transactions as a best practice.
        // See all available options at https://developers.google.com/pay/api/web/reference/object
        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];

        cardPaymentMethod.parameters.billingAddressRequired = true;
        cardPaymentMethod.parameters.billingAddressParameters = {
          format: 'FULL',
          phoneNumberRequired: true,
        };

        const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);

        this.handleGooglePayPayload(paymentData);

        const parsedPaymentData = await this.googlePaymentInstance.parseResponse(paymentData);

        await this.placeGooglePayOrder(parsedPaymentData);

        eventTracker.trackEvent({
          event: 'payment_complete',
          data: { paymentType: 'GooglePay' },
        });
      } catch (err) {
        const errorMsgWhiteList = ['User closed the Payment Request UI'];

        if (!errorMsgWhiteList.some((listItem) => err.message?.includes(listItem))) {
          throw new GooglePaymentError('Error in onSubmit()!', err, this.paymentProvider);
        }
      }
    },

    async placeGooglePayOrder(data) {
      try {
        // Setting the nonce and payment method name
        this.setBraintreePaymentData({
          nonce: data.nonce,
          method: 'googlepay',
        });

        await this.placeBraintreeOrder();
      } catch (err) {
        throw new GooglePaymentError('Error in placeGooglePayOrder()!', err, this.paymentProvider);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
