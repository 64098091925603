<template>
  <div class="qty-field">
    <button
      type="button"
      :disabled="isCartUpdateInProgress || quantityFieldVal === 1"
      class="btn-decrease"
      aria-label="Decrease Quantity"
      @click="onDecrementQuantity()"
    ></button>

    <input type="number" min="1" v-model.number="quantityFieldVal" readonly />

    <button
      type="button"
      :disabled="isCartUpdateInProgress"
      class="btn-increase"
      aria-label="Increase Quantity"
      @click="onIncrementQuantity()"
    ></button>

    <p v-if="isShowQtyMessage()" v-text="`Please enter a quantity of 1 or more.`"></p>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useCartStore } from '@/stores/CartStore.ts';

export default {
  name: 'ProductQty',
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    onIncrementQuantity: {
      type: Function,
      default: () => {},
    },
    onDecrementQuantity: {
      type: Function,
      default: () => {},
    },
    onSetQuantity: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    text: null,
  }),
  computed: {
    ...mapState(useCartStore, ['isCartUpdateInProgress']),

    quantityFieldVal: {
      get() {
        return this.quantity;
      },
      set(val) {
        const intVal = parseInt(val);

        if (isNaN(intVal) || intVal < 1) {
          this.onSetQuantity(1);
        } else {
          this.onSetQuantity(intVal);
        }
      },
    },
  },
  methods: {
    // Show the customer a message if qty is less then or equal to 0
    isShowQtyMessage() {
      return !this.quantity;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
