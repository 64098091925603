<template>
  <!-- The initial spinner when the app is just starting up -->
  <div
    v-if="isShowInitialLoadingState"
    class="tw-col-span-12 tw-h-[100vh] tw-flex tw-justify-center tw-items-center"
    data-test="initial-spinner"
    :style="`background: transparent`"
  >
    <div class="st-spinner" />
  </div>

  <div
    v-else
    class="app-container tw-grid tw-grid-cols-12"
    :class="{
      'is-app-loading': loading.app,
      'sticky-apple-pay-is-visible': isShowStickyApplePaySummary,
      'gallery--open': isProductGalleryOpen,
      'pdp-view': isPDPView,
      'payment-popup-view': isPaymentPopup,
      'is-splash-complete': isSplashPageTransitionComplete,
    }"
    :style="`--primary-brand-colour: ${primaryColor}; --pdp-background-colour: #F1F1F1`"
    @animationend="onSplashScreenAnimationEnd"
  >
    <!-- Splash Screen after app has loaded -->
    <template v-if="loading.splash">
      <transition name="fade" mode="out-in">
        <PaymentPopupSplashScreen v-if="isPaymentPopup" />
        <SplashScreenPDP v-else-if="isPDPView" />
        <SplashScreenDefault v-else />
      </transition>
    </template>

    <!-- The key components have loaded, show the checkout view -->
    <!-- The gradient on the background allows for the brand colour in the top portion and avoids it showing at the bottom of the page -->
    <div class="main-container-wrap tw-col-span-12" ref="mainCont" v-show="!loading.splash">
      <!-- PDP view header-->
      <HeaderPDP v-if="isPDPView" />

      <!-- Default header-->
      <HeaderDefault v-else />

      <!-- In-App loading state -->
      <div
        v-if="loading.app"
        class="tw-flex tw-justify-center tw-items-center tw-bg-white"
        data-test="content-loading-spinner"
      >
        <div class="st-spinner" />
      </div>

      <div v-else class="main-container">
        <main>
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <div
                :key="route.name"
                class="view-container"
                :class="{
                  'slide-up-view':
                    (route.name === 'Product' && route.redirectedFrom?.name) === 'Root',
                }"
              >
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </main>
      </div>

      <Footer v-if="isShowFooter" />
    </div>

    <LaunchPaymentPopup
      v-if="
        !isPaymentPopup &&
        getIsShopFrontInitialised &&
        paymentPopupState &&
        paymentPopupState !== 'IS_CLOSED'
      "
      data-test="launch-payment-popup"
      :paymentPopupState="paymentPopupState"
      :paymentProviderToken="paymentConfig?.paymentProviderToken"
      :cartJwt="cartJwt"
      :paymentProvider="paymentProvider"
    />

    <Snackbar v-show="!loading.app && !loading.splash" />
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useProductStore } from '@/stores/ProductStore';
import { useCartStore } from '@/stores/CartStore';
import { usePaymentStore } from '@/stores/PaymentStore';

import SplashScreenDefault from '@/components/SplashScreen/default/index.vue';
import SplashScreenPDP from '@/components/SplashScreen/PDP/index.vue';
import PaymentPopupSplashScreen from './components/SplashScreen/PaymentPopup/index.vue';
import Logo from '@/components/Logo/index.vue';
import IconBack from '@/components/icons/IconBack.vue';
import LaunchPaymentPopup from './components/Payment/LaunchPaymentPopup.vue';
import Footer from '@/components/Footer/index.vue';
import HeaderDefault from '@/components/Header/default/index.vue';
import HeaderPDP from '@/components/Header/PDP/index.vue';
import Snackbar from '@/components/Snackbar/index.vue';

import constants from '@/constants';
import { useRetailerStore } from '@/stores/RetailerStore';
import { getRouter } from '@/router';
import eventTracker from '@/helpers/eventTracker';
import { ShopthruError } from '@/types/errors.types';
import { LocalStorageHandler } from '@/helpers/localStorage/index';

export default {
  components: {
    Logo,
    IconBack,
    SplashScreenDefault,
    SplashScreenPDP,
    PaymentPopupSplashScreen,
    LaunchPaymentPopup,
    Footer,
    HeaderDefault,
    HeaderPDP,
    Snackbar,
  },
  data() {
    return {
      isSplashPageTransitionComplete: false,
    };
  },
  computed: {
    ...mapState(useMainStore, [
      'loading',
      'isPreviewMode',
      'isPDPView',
      'isPaymentPopup',
      'paymentPopupState',
      'getIsShopFrontInitialised',
    ]),
    ...mapState(usePaymentStore, ['paymentConfig']),
    ...mapState(useRetailerStore, ['isRetailerInitialised', 'paymentProvider', 'primaryColor']),
    ...mapState(useProductStore, ['isShowStickyApplePaySummary', 'isProductGalleryOpen']),
    ...mapState(useCartStore, ['cartJwt']),

    isShowInitialLoadingState() {
      if (this.$route.name === 'Error') {
        return false;
      }

      return !this.isRetailerInitialised;
    },

    isShowFooter() {
      // TODO add Payment Popup when merged

      //Footer shouldn't be on the Shop View
      return this.isSplashPageTransitionComplete && !['ShopProduct'].includes(this.$route.name);
    },
  },
  setup() {
    const appInstance = inject('appInstance');
    const mainStore = useMainStore();
    const paymentPopupLocation = window.location;
    const paymentPopupupLocationSearchParams = new URLSearchParams(paymentPopupLocation.search);
    const isPaymentPopup =
      paymentPopupupLocationSearchParams.get(
        constants.PAYMENT_POPUP_WINDOW.isPaymentPopupLaunchIdentifier,
      ) === 'true'; // i.e. is dedicated popup window for payment workarounds
    const checkoutLocation = LocalStorageHandler.get('checkoutLocation', isPaymentPopup);
    const checkoutLocationSearchParams = new URLSearchParams(checkoutLocation.search);
    let mainStoreData;

    // Payment Popup flow
    if (isPaymentPopup) {
      mainStoreData = {
        isPaymentPopup: true,
        code: null,
        parentLocation: null,
        isPreviewMode: false,
        isPDPView: false,
        userSupportedApplePayVersion: null,
      };
    }

    // Default flow
    else {
      mainStoreData = {
        code: checkoutLocation.pathname.split('/').pop(), // Get short code from last path fragment,
        parentLocation: checkoutLocationSearchParams.get('parentlocation'),
        isPreviewMode: checkoutLocationSearchParams.get('isPreviewMode') === 'true',
        isPDPView: checkoutLocation.pathname.split('/').includes('shop'),
        userSupportedApplePayVersion: checkoutLocationSearchParams.get('applePayVersion') || null,
        isPaymentPopup: false,
      };
    }

    mainStoreData.posthogSessionId = checkoutLocationSearchParams.get('posthogSessionId') || null;

    mainStore.loadJsAssets();
    mainStore.setData(mainStoreData);

    // Redirect to Payment Popup is specified
    if (isPaymentPopup) {
      getRouter().then((router) => {
        router.replace({ name: 'PaymentPopup' });
      });
    }

    return {
      appInstance,
    };
  },
  async created() {
    const component = this;
    const router = await getRouter();

    // Listen for unhandled promise rejections
    window.addEventListener('unhandledrejection', (e) => {
      const reasonObj = e.reason;

      if (import.meta.env.VITE_APP_ENV !== 'prod') {
        console.info('Dev note - global unhandledrejection handler called', reasonObj);
      }

      let isGoToErrorPage = false;

      // Whitelist the error types so we don't go to error page on just any unhandled error event
      if (Object.getPrototypeOf(reasonObj) instanceof ShopthruError) {
        // Handle all appropriate payment errors by going to the error page, this will be more finely tuned moving forwards.
        isGoToErrorPage = true;

        // Ignore the error thrown when user closes the Google Pay dialogue
        if (reasonObj.sourceError?.statusCode === 'CANCELED') {
          isGoToErrorPage = false;
        }
      }

      if (isGoToErrorPage) {
        e.preventDefault();

        component.goToErrorPage({
          error: reasonObj,
        });
      } else {
        eventTracker.trackEvent({
          event: 'unhandledrejection_error_thrown',
          data: { error: reasonObj },
        });
      }
    });

    /**
     * When query param `isDialogueInEffect` is set, close all dialogues on browser back.
     * This prevents the user from being navigated back and away from the checkout when user the browser back
     * button to e.g. dismiss the product gallery image overlay.
     */
    this.$watch(
      () => router.currentRoute.value.query?.isDialogueInEffect,
      (_, oldVal) => {
        if (oldVal === 'true') {
          this.appInstance?.config.globalProperties.$dialogueListener({
            doCloseAllDialogues: true,
          });
        }
      },
    );
  },
  mounted() {
    // Make this Vue instance accessible from the payment popup
    window.ParentVueInstance = this;

    eventTracker.trackEvent({
      event: 'app_loaded',
    });
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout', 'goToErrorPage']),

    onSplashScreenAnimationEnd(e) {
      if (e.animationName === 'slideUpViewContainer') {
        this.isSplashPageTransitionComplete = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import './styles/_app.scss';
</style>
