import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import router, { initNavigationGuards } from './router/router';
import App from './App.vue';
import packageJson from '../package.json';
import { useMainStore } from '@/stores/MainStore.ts';
import { piniaAppInstancePlugin } from '@/plugins/piniaAppContext';
import postMessenger, { initInboundPostMessaging } from '@/plugins/postMessenger';
import dialogueListener from '@/plugins/dialogueListener';
import { getPosthog } from '@/plugins/postHog';
import globalEventEmitter from '@/plugins/globalEventEmitter';
import eventTracker from '@/helpers/eventTracker';
import mediaMatchInspector from '@/helpers/mediaMatchInspector';

const app = createApp(App);

const initSentry = (posthog) => {
  const sentryOrganisationName = import.meta.env.VITE_SENTRY_ORGANISATION_NAME;
  const sentryProjectId = import.meta.env.VITE_SENTRY_PROJECT_ID;

  if (window.Cypress || import.meta.env.VITE_APP_IS_IN_E2E === 'true') {
    window.Sentry = {
      captureException: () => {},
    };

    return;
  }

  if (!sentryOrganisationName || !sentryProjectId) {
    throw new Error('Missing required Sentry environment info!');
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),

      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),

      posthog.sentryIntegration({
        organization: sentryOrganisationName,
        projectId: sentryProjectId,
      }),
    ],
    tracesSampleRate: 1.0,
    // TODO needs thought tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV || 'unspecified',
    release: packageJson.version,
  });
};

const initPosthog = () => {
  const mainStore = useMainStore();
  const params = new URLSearchParams(window.location.search);
  const posthogSessionId = params.get('posthogSessionId') || null;

  mainStore.posthogSessionId = posthogSessionId;
};

const posthog = getPosthog();

initSentry(posthog); // See https://shopthru.sentry.io/projects/checkout-fe/getting-started/?product=session-replay&product=performance-monitoring&siblingOption=vue3

const pinia = createPinia();
pinia.use(piniaAppInstancePlugin(app));

app.use(pinia);
app.use(router);
app.use(postMessenger);
app.use(dialogueListener);
app.use(mediaMatchInspector);
app.use(globalEventEmitter);
app.provide('appInstance', app);

initPosthog();

if (import.meta.env.VITE_FRAMELESS_DEV_MODE === 'true') {
  // Stub out postMessage to avoid JS errors in payment libraries trying to look for a parent iFrame.
  window.postMessage = () => {};
} else {
  initInboundPostMessaging();
}

app.config.errorHandler = (error, instance, info) => {
  const mainStore = useMainStore();

  console.error('Error boundary reached with error', error);

  eventTracker.trackEvent({
    event: 'handled_error_thrown',
    data: { error: error },
  });

  try {
    Sentry.captureException(error);
  } catch (err) {
    console.error('Failed to send Sentry log!', err);
  }

  mainStore.clearXhrTracker();

  mainStore.goToErrorPage({
    error,
    isSkipLogging: true,
  });
};

initNavigationGuards(router);

router.isReady().then(() => {
  app.mount('#app');
});

console.log(
  `Shopthru checkout-frame version ${packageJson.version} running in environment: ${import.meta.env.VITE_APP_ENV}`,
);
