<template>
  <!-- Welcome message -->
  <div class="tw-col-span-12 tw-text-center tw-mt-[20%] tw-text-lg">
    <div>Connecting you with {{ companyName }}</div>

    <!-- Loading dots -->
    <div class="dots-loading tw-text-[55px]">
      <span class="loading__letter">.</span>
      <span class="loading__letter">.</span>
      <span class="loading__letter">.</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';

export default {
  name: 'SplashScreenPDP',

  computed: {
    ...mapState(useRetailerStore, ['companyName']),
  },
};
</script>
