import { Sentry } from '@/helpers/moduleMockWrappers/sentry';
import sendEventApi from '@/api/eventTracker/index';
import { getPosthog } from '@/plugins/postHog';
import { ExpectedEvents, ExpectedInternalTrackingEvents } from '@/types/events.types';
import { useCartStore } from '@/stores/CartStore';
import { useMainStore } from '@/stores/MainStore';
import { useProductStore } from '@/stores/ProductStore';
import { useRetailerStore } from '@/stores/RetailerStore';
import { usePublisherStore } from '@/stores/PublisherStore';

import type { RouteLocationNormalized } from 'vue-router';
import type { ExpectedInternalTrackingEventsType, ExpectedEventsType } from '@/types/events.types';
import type { CartJwtPayload } from '@/types/cart.types';

type TrackEventArgs = { event: ExpectedEventsType; data?: unknown };

export type InternalEventPayload = {
  event_name: ExpectedEventsType;
  event: undefined;
  data: unknown;
};
// TODO Add a type for external event type payload

const expectedPaymentTypes = ['paypal', 'googlepay', 'applepay', 'card'];

// @throws
const doValidateEvent = (args: TrackEventArgs) => {
  let isValid = true;

  if (!args) {
    console.error('No event args supplied!');
    isValid = false;
  } else if (!args?.event) {
    console.error('No event supplied!');
    isValid = false;
  } else if (!ExpectedEvents.includes(args?.event)) {
    console.error('Unrecognised event supplied!');
    isValid = false;
  }

  // Custom event validation
  switch (args.event) {
    case 'payment_type_selected':
      if (!expectedPaymentTypes.includes(args?.data?.paymentType)) {
        isValid = false;
      }
      break;

    default:
      break;
  }

  if (!isValid) {
    throw new Error(`Invalid event args: ${JSON.stringify(args)}`);
  }
};

const init = ({ posthogSessionId }: { posthogSessionId: string | null }) => {
  const posthog = getPosthog();
    
  if (import.meta.env.VITE_APP_ENV !== 'prod') {
    console.debug('Initialising Posthog with Session ID:', posthogSessionId);
  }

  if (!posthogSessionId) {
    const msg = 'Could not initialise Posthog Session ID!';

    console.error(msg);
    Sentry.captureException(msg);
  }

  posthog.identify(posthogSessionId!);
};

const trackEvent = (args: TrackEventArgs) => {
  const mainStore = useMainStore();
  const productStore = useProductStore();
  const cartStore = useCartStore();
  const retailerStore = useRetailerStore();
  const publisherStore = usePublisherStore();
  const posthog = getPosthog();
  const { cart, cartJwt } = cartStore;

  if (import.meta.env.VITE_APP_ENV !== 'prod') {
    console.debug('Tracking event:', args, mainStore.posthogSessionId);
  }

  try {
    // TODO check if error prop is of type 'Error' and then transform to string.

    doValidateEvent(args);

    // Internal B/E tracking
    if (ExpectedInternalTrackingEvents.includes(args.event as ExpectedInternalTrackingEventsType)) {
      if (cart) {
        if (!cart.id) {
          const msg = `Skipping internal event tracking for event ${args.event} as no Cart ID available.`;

          console.debug(msg);
          Sentry.captureException(msg);
        } else {
          sendEventApi.sendEvent(
            {
              ...args,
              event_name: args?.event,
              event: undefined,
              data: args?.data || {},
            },
            cart.id,
            cartJwt as CartJwtPayload,
          );
        }
      } else {
        console.debug(`Skipping internal event tracking for event ${args.event} as no Cart available.`);
      }
    }

    // External tracking
    ////////////////////

    // Register Group properties - Retailer details
    if (retailerStore.ref) {
      posthog.group('retailer', retailerStore.ref, {
        name: retailerStore.retailerName,
      });
    }

    // Register Group properties - Publisher details
    if (publisherStore.publisherId) {
      posthog.group('publisher', publisherStore.publisherId, {
        name: publisherStore.publisherName,
        url: publisherStore.publisherUrl,
      });
    }

    // Register Group properties - Product details
    if (productStore.product) {
      posthog.group('product', productStore.product.id, {
        name: productStore.product.name,
        sku: productStore.product.sku,
      });
    }
    
    // Capture the event
    const enrichedCaptureProperties = {
      '$session_id': mainStore.posthogSessionId,
      '$groups': {
        'publisher_page_url': mainStore.parentLocation?.href || undefined,
        'retailer': retailerStore.ref || undefined,
        'publisher': publisherStore.publisherId || undefined,
        'product': productStore.product?.id || undefined,
      },
    } as {
      '$session_id': string;
      '$groups': {
        'publisher_page_url'?: string;
        'retailer'?: string;
        'publisher'?: string;
        'product'?: string;
      }

      product_short_code?: string;
      product_name?: string;
      cart_quantity?: number;
      cart_grand_total?: number;
      cart_transaction_id?: string;
      cart_is_discount_applied?: boolean;
    };

    if (mainStore.code) {
      enrichedCaptureProperties.product_short_code = mainStore.code;
    }

    if (productStore.product) {
      enrichedCaptureProperties.product_name = productStore.product.name;
    }

    if (cartStore.cart) {
      enrichedCaptureProperties.cart_quantity = cartStore.cart.productQuantity;
      enrichedCaptureProperties.cart_grand_total = cartStore.cart.pricing?.grandTotal;
      enrichedCaptureProperties.cart_transaction_id = cartStore.cart.transactionId;
      enrichedCaptureProperties.cart_is_discount_applied = cartStore.cart.isDiscountApplied;
    }

    const capturePayload = {
      ...args.data as {},
      ...enrichedCaptureProperties,
    };

    if (import.meta.env.VITE_APP_ENV !== 'prod') {
      console.debug('Capturing PostHog Event:', capturePayload);
    }

    posthog.capture(args.event, capturePayload);
  } catch (err: any) {
    console.error('Failed to track event!', err);
    Sentry.captureException(err, { extra: args });
  }
};

const trackPageViewEvent = (to: RouteLocationNormalized) => {
  switch (to.name) {
    case 'Product':
      trackEvent({ event: 'product_page' });
      break;

    case 'ShopProduct':
      trackEvent({ event: 'pdp_product_page' });
      break;

    case 'Shipping':
      trackEvent({ event: 'shipping_page' });
      break;

    case 'BraintreeCheckout':
      trackEvent({
        event: 'checkout_page',
        data: {
          paymentProvider: 'braintree',
        },
      });
      break;

    case 'PPCPCheckout':
      trackEvent({
        event: 'checkout_page',
        data: {
          paymentProvider: 'ppcp',
        },
      });
      break;

    case 'StripeCheckout':
      trackEvent({
        event: 'checkout_page',
        data: {
          paymentProvider: 'stripe',
        },
      });
      break;

    case 'AdyenCheckout':
      trackEvent({
        event: 'checkout_page',
        data: {
          paymentProvider: 'adyen',
        },
      });
      break;

    case 'PaymentPopup':
      trackEvent({ event: 'payment_popup_page' });
      break;

    case 'Thankyou':
      trackEvent({ event: 'thankyou_page' });
      break;

    case 'ProductNotFound':
      trackEvent({ event: 'product_not_found_page' });
      break;

    case 'ProductNotFoundPDP':
      trackEvent({ event: 'pdp_product_not_found_page' });
      break;

    case 'Error':
      trackEvent({ event: 'error_page' });
      break;

    default:
      break;
  }
};

export default {
  init,
  trackEvent,
  trackPageViewEvent,
};
