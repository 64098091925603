<template>
  <div v-if="isRenderComponent" class="paypal-container">
    <div id="paypal-button"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useRetailerStore } from '@/stores/RetailerStore';
import { BraintreePaymentAvailability } from '@/helpers/braintreePayments.ts';
import eventTracker from '@/helpers/eventTracker.ts';
import { PayPalPaymentError } from '@/types/errors.types.ts';
import { Utils } from '@/helpers/utils';
import constants from '@/constants';

export default {
  name: 'BraintreePayPal',
  props: {
    paypalApi: { type: Object, required: false }, // Can be overriden in tests
    braintreeApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      actions: null,
      shippingOption: null,
      paypalActions: null,
      isRenderComponent: true,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['getTotalCartPrice', 'getTotalPaymentPrice']),
    ...mapState(useRetailerStore, ['paymentProvider']),
  },
  unmounted() {
    const button = document.getElementById('paypal-button');

    if (button) return (document.getElementById('paypal-button').innerHTML = '');
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'paypal',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'braintree',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.init();
  },
  methods: {
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placeBraintreeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(useMainStore, ['setPaymentButtonInitialised']),

    async init() {
      const paypalApi = this.paypalApi || window.paypal;
      const braintreeApi = this.braintreeApi || window.braintree;

      try {
        const parent = this;
        const storeCurrency = this.currency;

        const clientInstance = await braintreeApi.client.create({
          authorization: this.paymentConfig.paymentProviderToken,
        });

        if (
          !BraintreePaymentAvailability.isBraintreePaymentAvailable(clientInstance, 'paypal') ||
          !this.getIsPaymentMethodEnabled('paypal')
        ) {
          this.setPaymentButtonInitialised('paypal', 'NOT_ENABLED');
          this.isRenderComponent = false;
          return;
        }

        braintreeApi.paypalCheckout.create(
          {
            client: clientInstance,
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            paypalApi.Button.render(
              {
                env: parent.paymentConfig.environment,
                fundingSource: paypalApi.FUNDING.PAYPAL,
                intent: 'authorize', // TODO is this required? Red herring?
                commit: true,
                style: {
                  color: 'gold',
                  shape: 'pill',
                  height: 45,
                  tagline: false,
                },

                payment() {
                  eventTracker.trackEvent({
                    event: 'payment_type_selected',
                    data: { paymentType: 'paypal' },
                  });

                  // Else render modal
                  return paypalCheckoutInstance.createPayment({
                    flow: 'checkout',
                    amount: parent.getTotalCartPrice,
                    currency: storeCurrency.currencyCode,
                    enableShippingAddress: true,
                    shippingOptions: [],
                  });
                },

                async onShippingChange(data, actions) {
                  const newShippingOptions = [];
                  const shippingOptionData = data.selected_shipping_option;
                  const shippingCountry = data.shipping_address.country_code;

                  // Check if the shipping country is allowed
                  if (!constants.ALLOWED_SHIPPING_COUNTRY_CODES.includes(shippingCountry)) {
                    return actions.reject();
                  }

                  // Set selected shipping Option
                  parent.shippingOption = shippingOptionData || null;

                  const address = {
                    streetAddress: [''],
                    city: data.shipping_address.city,
                    region: data.shipping_address.state,
                    postalCode: data.shipping_address.postal_code,
                    country: data.shipping_address.country_code,
                  };

                  parent.setAddress('billing', address);
                  parent.setAddress('shipping', address);

                  await parent.fetchShippingMethods();

                  (parent.shippingMethods || []).forEach((method, key) => {
                    // If there is not already a shipping method set then lets set one!
                    if (key === 0 && !shippingOptionData) {
                      parent.setShippingOption(method);
                    }

                    // Create array of methods in the required format
                    newShippingOptions.push({
                      id: method.code,
                      type: 'SHIPPING',
                      label: method.label,
                      selected: parent.shippingOption.id === method.code,
                      amount: {
                        value: method.amount,
                        currency_code: storeCurrency.currencyCode,
                      },
                    });
                  });

                  // Set the method in the app
                  await parent.setShippingMethod(parent.shippingOption.id);

                  // Once everything is calculated then set the shipping options and updated price in paypal window
                  return actions.order.patch([
                    {
                      op: !shippingOptionData ? 'add' : 'replace',
                      path: "/purchase_units/@reference_id=='default'/shipping/options",
                      value: newShippingOptions,
                    },
                    {
                      op: 'replace',
                      path: "/purchase_units/@reference_id=='default'/amount",
                      value: {
                        currency_code: storeCurrency.currencyCode,
                        value: parseFloat(parent.getTotalPaymentPrice, 10),
                        /**
                         * seems to have a logic error when buying something with a shipping value and discount is applied (mismatch prices)
                         * we've decided to remove this block for now as it's not rendered out anyway - DS 28th Oct 24
                        breakdown: {
                          item_total: {
                            currency_code: storeCurrency.currencyCode,
                            value: parseFloat(parent.getTotalCartPrice, 10),
                          },
                          shipping: {
                            currency_code: storeCurrency.currencyCode, // DS parent.shippingOption.amount.currency_code,
                            value: parseFloat(parent.shippingOption.amount.value, 10),
                          },
                        },
                        */
                      },
                    },
                  ]);
                },

                /**
                 * On authorization then handle payload and place order
                 */
                onAuthorize(paymentData) {
                  return paypalCheckoutInstance.tokenizePayment(paymentData, (err, payload) => {
                    parent.handlePayPalPayload(payload); // Submit `payload.nonce`

                    eventTracker.trackEvent({
                      event: 'payment_complete',
                      data: { paymentType: 'PayPal' },
                    });
                  });
                },
              },
              '#paypal-button',
            );
          },
        );

        this.setPaymentButtonInitialised('paypal', 'INITIALISED');
      } catch (err) {
        throw new PayPalPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    setShippingOption(method) {
      try {
        this.shippingOption = {
          id: method.code,
          type: 'SHIPPING',
          label: method.label,
          selected: true,
          amount: {
            value: method.amount,
            currency_code: this.currency.currencyCode,
          },
        };
      } catch (err) {
        throw new PayPalPaymentError('Error in setShippingOption()!', err, this.paymentProvider);
      }
    },

    // @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#~tokenizePayload
    async handlePayPalPayload(data) {
      try {
        const shippingAddress = {
          streetAddress: [
            data.details.shippingAddress.line1,
            data.details.shippingAddress.line2 ?? '',
          ],
          city: data.details.shippingAddress.city,
          postalCode: data.details.shippingAddress.postalCode,
          region: data.details.shippingAddress.state,
          country: data.details.shippingAddress.countryCode,
        };

        const billingAddress = {
          streetAddress: [
            data.details.billingAddress.line1,
            data.details.billingAddress.line2 ?? '',
          ],
          city: data.details.billingAddress.city,
          postalCode: data.details.billingAddress.postalCode,
          region: data.details.billingAddress.state,
          country: data.details.billingAddress.countryCode,
        };

        this.setCustomer('shipping', {
          firstName: data.details.shippingAddress.recipientName,
          lastName: data.details.shippingAddress.recipientName,
        });

        this.setCustomer('billing', {
          firstName: data.details.firstName,
          lastName: data.details.lastName,
          phone: data.details.phone,
          email: data.details.email,
        });

        this.setAddress('shipping', shippingAddress);
        this.setAddress('billing', billingAddress);

        // Set payment nonce
        this.setBraintreePaymentData({
          nonce: data.nonce,
          method: 'paypal',
        });

        await this.placeBraintreeOrder();
      } catch (err) {
        throw new PayPalPaymentError('Error in handlePayPalPayload()!', err, this.paymentProvider);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
