<template>
  <dl
    class="product-details-tab tab-panel tw-mt-6"
    id="product-details-tab"
    :class="isTabOpen ? 'tab-open' : 'tab-closed'"
  >
    <dt
      class="tab-trigger"
      aria-labelledby="product-details-h4"
      tabindex="0"
      role="button"
      @keydown.enter="
        toggleTab();
        readMore = false;
      "
      @click="
        toggleTab();
        readMore = false;
      "
    >
      <h4 id="product-details-h4">Product Details</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content" :class="readMore && isTabOpen ? 'read-open' : ''">
      <!-- Whitespace pre-line approximates line breaks in text layout when no markup supplied -->
      <div
        v-html="product.description"
        :class="{
          'tw-whitespace-pre-line': !isTextContainsHTML(product.description),
          'custom-content': isTextContainsHTML(product.description),
        }"
      />

      <button
        v-if="displayedTextLength"
        tabindex="1"
        class="button read-more-btn tw-font-normal tw-w-full tw-text-sm tw-bg-white tw-h-9"
        @click="readMore = !readMore"
      >
        <span v-if="readMore">Less</span>
        <span v-else>More</span>
      </button>
    </dd>
  </dl>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useMainStore } from '@/stores/MainStore';
import TextFormat from '@/helpers/textFormat';

export default {
  name: 'ProductDelivery',
  components: {
    IconArrow,
  },
  computed: {
    ...mapState(useProductStore, ['product']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    displayedTextLength() {
      return this.product.description?.length >= 300;
    },

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_DETAILS';
    },
  },
  data: () => ({
    readMore: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_DETAILS') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_DETAILS';
      }
    },
    isTextContainsHTML(text) {
      return TextFormat.isTextContainsHTML(text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
