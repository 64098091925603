<template>
  <div class="notification notification--error">
    <div class="notification__icon mb-5">
      <MarkIcon />
    </div>

    <div class="notification-text">
      <h2 class="colour-danger" v-text="messaging.message.title" data-test="error-msg-title" />

      <p
        v-html="messaging.message.subTitle"
        class="font-weight-light"
        data-test="error-msg-subtitle"
      />
    </div>

    <!-- Buttons -->
    <div class="tw-mt-20">
      <div v-for="(btn, i) in messaging.btns" :key="`button_${i}`" class="mt-15">
        <button
          type="button"
          @click="btn.onClick"
          :class="{
            button: true,
            'button--primary': btn.priority === 'primary',
            'button--secondary': btn.priority === 'secondary',
            'button--tertiary': btn.priority === 'tertiary',
          }"
          data-test="error-action-button"
        >
          {{ btn.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import * as Sentry from '@sentry/vue';
import MarkIcon from '@/components/icons/IconExclamationMark.vue';
import {
  ErrorBoundaryPageInferredError,
  ErrorBoundaryPageGenericError,
} from '@/types/errors.types';
import { useMainStore } from '@/stores/MainStore';
import eventTracker from '@/helpers/eventTracker';
import { LocalStorageHandler } from '@/helpers/localStorage/index';

/**
 * This file is intended to have minimal application dependancies in notifying the user of an error (that has broken the checkout journey).
 * This is done so that we don't make calls to things like Stores where the original error may have occured and end up in a cyclic state.
 */
export default {
  name: 'Error',
  components: {
    MarkIcon,
  },
  created() {
    if (this.isPaymentPopup) {
      return this.doPaymentPopupErrorPageTransfer({ error: this.error });
    }
  },
  mounted() {
    console.debug('Dev note - Error page reached:', this.error, this.$route.query);

    const isSkipLogging = this.$route.query.isSkipLogging;

    if (!isSkipLogging || isSkipLogging === 'false') {
      /**
       * Most of the time we'll arrive on this page from the global error boundary, where the Sentry log will already have been made.
       * However if we've come here explicitly via some other router call then send what error information we get from the URL.
       */
      Sentry.captureException(new ErrorBoundaryPageInferredError(JSON.stringify(this.error)));

      eventTracker.trackEvent({
        event: 'error_page',
        data: { error: this.error },
      });
    }

    this.endAppLoading();

    if (!this.getFallbackUrl) {
      const msg = 'Could not find fallback URL on reaching Error page!';
      console.error(msg);
      Sentry.captureException(msg);
    }
  },
  computed: {
    ...mapState(useMainStore, ['getFallbackUrl', 'isPaymentPopup', 'isPDPView']),

    isPaymentError() {
      return [
        'GooglePaymentError',
        'ApplePaymentError',
        'PayPalPaymentError',
        'HostedFieldsPaymentError',
        'StripePaymentError',
        'AdyenPaymentError',
        'PPCPPaymentCaptureError',
      ].includes(this.error?.name);
    },

    // Only whitelist codes that we absolutely know indicate that payment was not taken!
    isPaymentWasDefinitelyNotTaken() {
      return ['PPCPPaymentCaptureError'].includes(this.error?.name);
    },

    errorAnimation() {
      return errorAnimation;
    },

    error() {
      try {
        const error = this.$route.query.error;

        if (error) {
          return JSON.parse(error);
        }
      } catch (err) {
        console.error('Failed to parse error!');
        Sentry.captureException(err);
      }
    },

    messaging() {
      const restartLocation = LocalStorageHandler.get('checkoutLocation');

      const buttonProps = {
        closeCheckout: {
          onClick: this.closeCheckout,
          priority: 'secondary',
          text: 'Close Checkout',
        },
        goToRetailer: {
          onClick: this.goToFallbackUrl,
          priority: 'secondary',
          text: 'Go to Retailer',
        },
        goDirectToRetailer: {
          onClick: this.goToFallbackUrl,
          priority: 'secondary',
          text: 'Go direct to Retailer',
        },
        restartCheckout: {
          onClick: this.restartCheckout,
          priority: 'primary',
          text: 'Restart Checkout',
        },
      };

      const message = {
        title: 'Oops, there seems to be an issue',
        subTitle: 'We apologise for the inconvenience',
      };

      const btns = [];

      // No Product Short Code
      if (!restartLocation) {
        message.title = 'Unable to create Checkout';
        message.subTitle = 'We apologise for the inconvenience';

        if (!this.isPDPView) {
          btns.push(buttonProps.closeCheckout);
        }
      } else {
        // Payment Error
        if (this.isPaymentError) {
          const mainMsg =
            'Unfortunately there was a problem processing your payment and your order could not be placed';

          message.title = 'Your payment was unsuccessful';
          message.subTitle = mainMsg;
          btns.push(buttonProps.restartCheckout);

          if (this.isPaymentWasDefinitelyNotTaken) {
            message.subTitle = `${message.subTitle}<br /><br /><strong>Your payment was not taken</strong>`;
          }

          if (this.getFallbackUrl) {
            message.subTitle = `${message.subTitle}<br /><br />Please try again or visit the retailer`;
            btns.push(buttonProps.goToRetailer);
          }
        }

        // Generic Error
        else {
          btns.push(buttonProps.restartCheckout);

          if (this.getFallbackUrl) {
            btns.push(buttonProps.goToRetailer);
          }

          if (!this.isPDPView) {
            btns.push(buttonProps.closeCheckout);
          }
        }
      }

      return { message, btns };
    },
  },

  methods: {
    ...mapActions(useMainStore, ['endAppLoading', 'doPaymentPopupErrorPageTransfer']),

    goToFallbackUrl() {
      try {
        window.open(this.getFallbackUrl, '_blank');
      } catch (err) {
        console.error('Failed to open fallback URL!');
        Sentry.captureException(err);
        return;
      }

      this.closeCheckout();
    },

    restartCheckout() {
      const restartLocation = LocalStorageHandler.get('checkoutLocation');

      if (restartLocation) {
        return (window.location.href = restartLocation.href);
      }

      const failureMsg = 'Could not find original href to restart Checkout!';

      console.error(failureMsg);
      Sentry.captureException(new ErrorBoundaryPageGenericError(failureMsg));
    },

    closeCheckout() {
      try {
        parent.window.postMessage('closeCheckout', '*'); // TODO this is all old hat now
      } catch (err) {
        console.error('Failed to close Checkout!');
        Sentry.captureException(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Notification.scss';
</style>
