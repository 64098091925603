<template>
  <div
    v-show="snackBarMessage"
    class="snackbar-container"
    :class="{
      'snackbar-container--is-hidden': !isShowMessage,
      'snackbar-container--is-visible': isShowMessage,
      'snackbar-container--info': snackBarLevel === 'INFO',
      'snackbar-container--success': snackBarLevel === 'SUCCESS',
      'snackbar-container--warning': snackBarLevel === 'WARNING',
      'snackbar-container--error': snackBarLevel === 'ERROR',
    }"
  >
    <div v-html="snackBarMessage" />
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'Snackbar',
  computed: {
    ...mapWritableState(useMainStore, ['snackBarMessage', 'snackBarLevel', 'snackBarPeriod']),
  },
  data() {
    return {
      isShowMessage: false,
      defaultDisplayPeriod: 3000,
    };
  },
  watch: {
    snackBarMessage(newVal) {
      if (newVal) {
        this.isShowMessage = true;

        setTimeout(() => {
          // Reset snackbar state to default
          this.isShowMessage = false;
          this.snackBarMessage = null;
          this.snackBarLevel = 'INFO';
          this.snackBarPeriod = null;
        }, this.snackBarPeriod || this.defaultDisplayPeriod);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
