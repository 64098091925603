<template>
  <section>
    <h4>Your card details</h4>

    <fieldset>
      <!--  Card number   -->
      <div class="input-field">
        <div id="bthf-card-number" class="bthf-field"></div>
        <label for="bthf-card-number" class="text-caption label">Card Number</label>
        <IconError />
        <IconValid />
        <IconCard />
      </div>

      <div class="row col-2">
        <!--  Expiration Date   -->
        <div class="input-field">
          <div id="bthf-expiration-date" class="bthf-field"></div>
          <label for="bthf-expiration-date" class="text-caption label">
            {{ $isScreenSizeAtLeast('m') ? 'Expiry Date' : 'Exp.' }} MM/YY
          </label>
          <IconError />
          <IconValid />
        </div>

        <!--  CVV   -->
        <div class="input-field">
          <div id="bthf-cvv" class="bthf-field"></div>
          <label for="bthf-cvv" class="text-caption label">CVV</label>
          <IconError />
          <IconValid />
          <IconCVV />
        </div>
      </div>

      <button
        class="button button--primary button--full-width"
        data-test="submit-btn"
        @click="placeHostedFieldOrder()"
        :disabled="!cardData.valid"
        :loading="isLoading"
      >
        <div
          v-if="isLoading"
          :class="{
            'st-spinner': true,
            'st-spinner--disabled': !cardData.valid,
          }"
        />
        <span v-else>Purchase</span>
      </button>
    </fieldset>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useRetailerStore } from '@/stores/RetailerStore';
import IconError from '@/components/icons/IconError.vue';
import IconValid from '@/components/icons/IconValid.vue';
import IconCVV from '@/components/icons/IconCVV.vue';
import IconCard from '@/components/icons/IconCard.vue';
import eventTracker from '@/helpers/eventTracker.ts';
import { HostedFieldsPaymentError } from '@/types/errors.types';

export default {
  name: 'BraintreeHostedFields',
  components: {
    IconError,
    IconValid,
    IconCVV,
    IconCard,
  },
  props: {
    token: String,
  },
  data() {
    return {
      isLoading: false,
      hostedFieldsInstance: null,
      cardData: {
        valid: false,
        type: null,
        niceType: null,
      },
    };
  },
  computed: {
    ...mapState(usePaymentStore, ['braintreePaymentData', 'paymentConfig']),
    ...mapState(useShippingStore, ['address']),
    ...mapState(useCartStore, ['getTotalPaymentPrice']),
    ...mapState(useRetailerStore, ['paymentProvider']),
  },
  beforeUnmount() {
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.teardown();
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'placeBraintreeOrder',
      'setBraintreePaymentData',
      'setBraintreePaymentNonce',
    ]),

    async init() {
      try {
        const braintreeClientInstance = await braintree.client.create({
          authorization: this.paymentConfig.paymentProviderToken,
        });

        this.hostedFieldsInstance = await braintree.hostedFields.create({
          client: braintreeClientInstance,
          fields: {
            number: {
              selector: '#bthf-card-number',
              placeholder: '',
            },
            cvv: {
              selector: '#bthf-cvv',
              placeholder: '',
            },
            expirationDate: {
              selector: '#bthf-expiration-date',
              placeholder: '',
            },
          },
          styles: {
            input: {
              'font-size': '16px',
            },
          },
        });

        this.hostedFieldsInstance.on('cardTypeChange', (event) => {
          if (event.cards.length === 1) {
            this.cardData.type = event.cards[0].type;
            this.cardData.niceType = event.cards[0].niceType;
          } else {
            this.cardData.type = 'Unknown';
            this.cardData.niceType = 'Unknown';
          }
        });

        this.hostedFieldsInstance.on('notEmpty', (event) => {
          const fieldType = event.emittedBy,
            selector = event.fields[fieldType]['container'];
          selector.classList.add('not-empty');
        });

        this.hostedFieldsInstance.on('empty', (event) => {
          const fieldType = event.emittedBy,
            selector = event.fields[fieldType]['container'];
          selector.classList.remove('not-empty');
        });

        this.hostedFieldsInstance.on('validityChange', (event) => {
          this.cardData.valid =
            event.fields.cvv.isValid &&
            event.fields.expirationDate.isValid &&
            event.fields.number.isValid;
        });
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    placeHostedFieldOrder() {
      try {
        this.isLoading = true;

        this.hostedFieldsInstance.tokenize(async (tokenizeErr, payload) => {
          if (tokenizeErr) {
            this.setBraintreePaymentData({
              type: null,
              niceType: null,
              nonce: null,
              last4: null,
              bin: null,
            });

            throw new HostedFieldsPaymentError('Tokenize error!', tokenizeErr);
          }

          this.setBraintreePaymentData({
            type: this.cardData.type,
            niceType: this.cardData.niceType,
            nonce: payload.nonce,
            last4: payload.details.lastFour,
            bin: payload.details.bin,
            method: 'braintree',
          });

          if (this.braintreePaymentData.method === 'braintree') {
            // really means 'card'
            await this.threeDSecure();
          } else {
            await this.placeBraintreeOrder();
          }
        });
      } catch (err) {
        throw new HostedFieldsPaymentError(
          'Error in placing hosted fields order!',
          err,
          this.paymentProvider,
        );
      }
    },

    /**
     * Enable 3D Secure
     */
    async threeDSecure() {
      try {
        const brainTreeClientInstance = await braintree.client.create({
          authorization: this.paymentConfig.paymentProviderToken,
        });

        const threeDSecureInstance = await braintree.threeDSecure.create({
          version: 2,
          client: brainTreeClientInstance,
        });

        const secureResponse = await threeDSecureInstance.verifyCard({
          amount: this.getTotalPaymentPrice,
          nonce: this.braintreePaymentData.nonce,
          bin: this.braintreePaymentData.bin,
          email: this.address.customer.billing.email,
          billingAddress: {
            givenName: this.address.customer.billing.firstName,
            surname: this.address.customer.billing.lastName,
            phoneNumber: this.address.customer.billing.phone,
            streetAddress: this.address.billing.streetAddress[0],
            city: this.address.billing.city,
            region: this.address.billing.region,
            postalCode: this.address.billing.postalCode,
            countryCodeAlpha2: this.address.billing.country,
          },
          additionalInformation: {
            shippingGivenName: this.address.customer.shipping.firstName,
            shippingSurname: this.address.customer.shipping.lastName,
            shippingAddress: {
              streetAddress: this.address.shipping.streetAddress[0],
              city: this.address.shipping.city,
              region: this.address.shipping.region,
              postalCode: this.address.shipping.postalCode,
              countryCodeAlpha2: this.address.shipping.country,
            },
          },
          onLookupComplete: (data, next) => {
            next();
          },
        });

        if (secureResponse.liabilityShifted) {
          this.setBraintreePaymentNonce(secureResponse.nonce);

          eventTracker.trackEvent({
            event: 'payment_complete',
            data: { paymentType: 'Card Payment' },
          });

          await this.placeBraintreeOrder();
        } else {
          throw new Error('no response.liabilityShifted found');
        }
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in threeDSecure()!', err, this.paymentProvider);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
