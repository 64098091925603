<template>
  <div v-if="isRenderComponent" class="paypal-container">
    <div id="paypal-button"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useRetailerStore } from '@/stores/RetailerStore';
import { PayPalPaymentError } from '@/types/errors.types.ts';
import { Utils } from '@/helpers/utils';
import constants from '@/constants';

export default {
  name: 'PPCPPayPal',
  props: {
    paypalApi: { type: Object, required: false }, // Can be overriden in tests
    braintreeApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      actions: null,
      shippingOption: null,
      paypalActions: null,
      isRenderComponent: true,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapWritableState(useMainStore, ['snackBarMessage', 'snackBarLevel']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['cart', 'getTotalCartPrice', 'getTotalPaymentPrice']),
    ...mapState(useRetailerStore, ['paymentProvider']),
  },
  unmounted() {
    const button = document.getElementById('paypal-button');

    if (button) return (document.getElementById('paypal-button').innerHTML = '');
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'paypal',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.init();
  },
  methods: {
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placePPCPOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),

    onPaymentError(error) {
      this.goToErrorPage({
        error,
      });
    },

    async init() {
      const paypalApi = this.paypalApi || window.paypal;

      try {
        const parent = this;
        const storeCurrency = this.currency;

        if (!this.getIsPaymentMethodEnabled('paypal')) {
          this.setPaymentButtonInitialised('paypal', 'NOT_ENABLED');
          this.isRenderComponent = false;
          return;
        }

        // TODO https://developer.paypal.com/sdk/js/reference/#link-paypalbuttonsiseligible
        paypalApi
          .Buttons({
            style: {
              layout: 'vertical', // vertical or horizontal
              color: 'gold', // gold, blue, black, silver, white
              shape: 'pill', // rect, pill
              label: 'buynow', // paypal, pay, subscribe, checkout, buynow
              height: 45, // 25 - 55, a value around 40 is recommended
            },
            createOrder: () => {
              return Promise.resolve(this.cart.pspOrderId);
            },
            onApprove: function (data, actions) {
              if (
                data.liabilityShift &&
                (data.liabilityShift === 'NO' || data.liabilityShift === 'UNKNOWN')
              ) {
                throw new PayPalPaymentError(
                  'liabilityShift not handled!',
                  err,
                  this.paymentProvider,
                );
              }

              parent.placePPCPOrder('paypal');
            },

            onShippingAddressChange: this.onShippingAddressChange,
            onShippingOptionsChange: this.onShippingOptionsChange,
            onCancel: (data, actions) => {
              setTimeout(() => {
                this.snackBarMessage = 'Your PayPal payment was not taken.';
                this.snackBarLevel = 'SUCCESS';
              }, 1000); // Let PayPal mask dissolve away first
            },
            onError: (err) => {
              this.onPaymentError(
                new PayPalPaymentError('onError() called!', err, this.paymentProvider),
              );
            },
          })
          .render('#paypal-button');

        this.setPaymentButtonInitialised('paypal', 'INITIALISED');
      } catch (err) {
        throw new PayPalPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    setShippingOption(method) {
      try {
        this.shippingOption = {
          id: method.code,
          type: 'SHIPPING',
          label: method.label,
          selected: true,
          amount: {
            value: method.amount,
            currency_code: this.currency.currencyCode,
          },
        };
      } catch (err) {
        throw new PayPalPaymentError('Error in setShippingOption()!', err, this.paymentProvider);
      }
    },

    async onShippingAddressChange(data, actions) {
      const { shippingAddress } = data;

      const newShippingOptions = [];
      const shippingCountry = shippingAddress.countryCode;

      // Check if the shipping country is allowed
      if (!constants.ALLOWED_SHIPPING_COUNTRY_CODES.includes(shippingCountry)) {
        return actions.reject();
      }

      // Set selected shipping Option
      this.shippingOption = null;

      const address = {
        streetAddress: [''],
        city: shippingAddress.city,
        region: shippingAddress.state,
        postalCode: shippingAddress.postalCode,
        country: shippingCountry,
      };

      this.setAddress('billing', address);
      this.setAddress('shipping', address);

      await this.fetchShippingMethods();

      (this.shippingMethods || []).forEach((method, key) => {
        // Set selected shipping method to first provided
        if (key === 0) {
          this.setShippingOption(method);
        }

        // Create array of methods in the required format
        newShippingOptions.push({
          id: method.code,
          type: 'SHIPPING',
          label: method.label,
          selected: this.shippingOption.id === method.code,
          amount: {
            value: method.amount,
            currency_code: this.currency.currencyCode,
          },
        });
      });

      await this.setShippingMethod(this.shippingOption?.id);
    },

    async onShippingOptionsChange(data, actions) {
      const { selectedShippingOption } = data;
      const shippingOption = this.shippingMethods.find(
        (shippingMethod) => shippingMethod.code === selectedShippingOption.id,
      );

      if (!shippingOption) {
        throw new PayPalPaymentError(
          'Error in onShippingOptionsChange(), no opiotn matched!',
          err,
          this.paymentProvider,
        );
      }

      this.setShippingOption(shippingOption);

      await this.setShippingMethod(this.shippingOption.id);
    },
  },
};
</script>
