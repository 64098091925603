<template>
  <img tabindex="0" class="logo" :src="logo" :alt="`${companyName} Logo`" />
</template>

<script>
import { mapState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';

export default {
  name: 'Logo',
  computed: {
    ...mapState(useRetailerStore, ['logo', 'companyName']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
