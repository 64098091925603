<template>
  <header
    class="header-container tw-flex tw-justify-between tw-items-center tw-px-2 tw-py-1"
    :style="`background: ${primaryColor}`"
  >
    <Logo v-if="logo" :aria-label="companyName" />

    <div class="tw-text-right tw-font-light">
      <div class="tw-text-[10px] tw-opacity-80">SOLD AND DISPATCHED FROM</div>
      <div class="tw-text-base">{{ companyName }}</div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';
import Logo from '@/components/Logo/index.vue';

export default {
  name: 'HeaderDefault',
  components: {
    Logo,
  },
  computed: {
    ...mapState(useRetailerStore, ['primaryColor', 'logo', 'companyName']),
  },
};
</script>
