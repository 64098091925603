<template>
  <div class="tw-col-span-12">
    <HeaderDefault />

    <div
      class="default-splash-screen-body tw-flex tw-items-center tw-justify-center tw-text-white"
      :style="`background: ${primaryColor}`"
    >
      <div class="body-content">
        SEARCHING FOR DISCOUNTS
        <!-- Loading dots -->
        <span class="dots-loading">
          <span class="loading__letter">.</span>
          <span class="loading__letter">.</span>
          <span class="loading__letter">.</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useRetailerStore } from '@/stores/RetailerStore';
import { usePublisherStore } from '@/stores/PublisherStore';

import HeaderDefault from '@/components/Header/default/index.vue';

export default {
  name: 'SplashScreenDefault',
  components: {
    HeaderDefault,
  },
  computed: {
    ...mapState(useRetailerStore, ['logo', 'companyName', 'primaryColor']),
    ...mapState(usePublisherStore, ['publisherLogo', 'publisherName', 'showPublisherLogoOnLoad']),
  },
};
</script>

<style lang="scss">
@import './styles.scss';
</style>
