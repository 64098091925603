import { createWebHistory, createRouter } from 'vue-router';
import { getPosthog } from '@/plugins/postHog';
import eventTracker from '@/helpers/eventTracker';
import Product from '@/views/Product.vue';
import ErrorCatchAll from '@/views/Error.vue';
import Shipping from '@/views/Shipping.vue';
import BraintreeCheckout from '@/views/braintree/Checkout.vue';
import PPCPCheckout from '@/views/ppcp/Checkout.vue';
// import StripeCheckout from '@/views/stripe/Checkout.vue';
// import AdyenCheckout from '@/views/adyen/Checkout.vue';
import PaymentPopup from '@/views/PaymentPopup.vue';
import Thankyou from '@/views/Thankyou.vue';
import ProductNotFound from '@/views/ProductNotFound.vue';
import ShopProduct from '@/views/ShopProduct.vue';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { LocalStorageHandler } from '@/helpers/localStorage/index';

const routes = [
  {
    path: '/:product',
    name: 'Root',
  },
  {
    path: '/shop/product/:product',
    name: 'PdpRoot',
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: Shipping,
  },
  {
    path: '/braintree/checkout',
    name: 'BraintreeCheckout',
    component: BraintreeCheckout,
  },
  {
    path: '/ppcp/checkout',
    name: 'PPCPCheckout',
    component: PPCPCheckout,
  },
  /*
  {
    path: '/stripe/checkout',
    name: 'StripeCheckout',
    component: StripeCheckout,
  },
  */
  /*
  {
    path: '/adyen/checkout',
    name: 'AdyenCheckout',
    component: AdyenCheckout,
  },
  */
  {
    path: '/payment-popup',
    name: 'PaymentPopup',
    component: PaymentPopup,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorCatchAll,
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: Thankyou,
  },
  {
    path: '/product-not-found',
    name: 'ProductNotFound',
    component: ProductNotFound,
  },
  {
    path: '/shop/product/product-not-found',
    name: 'ProductNotFoundPDP',
    component: ProductNotFound,
  },
  {
    path: '/shop/product',
    name: 'ShopProduct',
    component: ShopProduct,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.getElementById('app');
    if (element) return elementScrollIntoView(element, { behavior: 'instant' });
  },
});

export const initNavigationGuards = (router) => {
  const posthog = getPosthog();

  // Before Each
  //////////////////////////////////////////
  router.beforeEach((to, from, next) => {
    const sanitisedWindow = { ...window.location };
    const sanitisedWindowUrl = new URL(sanitisedWindow.href);
    const queryParamsToStrip = ['isDialogueInEffect']; // Things we don't want to be persisted like UI flags

    // Remove the query params we don't want to persist
    queryParamsToStrip.forEach((queryParam) => {
      sanitisedWindowUrl.searchParams.delete(queryParam);
    });

    // Update the search property based on any stripped query params
    if (sanitisedWindowUrl.search.length) {
      sanitisedWindowUrl.search = sanitisedWindowUrl.href.split('?')[1];
    }

    // Update the main Location object ready for storage minus things we stripped
    sanitisedWindow.href = sanitisedWindowUrl.href;
    sanitisedWindow.search = sanitisedWindowUrl.search;

    // Set local storage on entry to app
    switch (to.name) {
      case 'Root':
      case 'PdpRoot':
        LocalStorageHandler.set('checkoutLocation', sanitisedWindow);

      default:
        break;
    }

    // Redirect to app entry point
    switch (to.name) {
      case 'Root':
        // Entry to default Checkout flow
        next({ name: 'Product' });
        break;

      case 'PdpRoot':
        // Entry to PDP flow
        next({ name: 'ShopProduct' });
        break;

      default:
        next();
        break;
    }
  });

  // After Each
  //////////////////////////////////////////
  router.afterEach((to) => {
    posthog.capture('$pageview');

    eventTracker.trackPageViewEvent(to);
  });
};

export default router;
