<template>
  <footer
    :class="{
      'tw-pt-5 tw-pb-5 tw-text-[8px] tw-font-light tw-text-gray-400': true,
      'tw-px-2 tw-items-center tw-flex-col tw-text-center': $isScreenSizeLessThan('m'),
      'tw-grid tw-grid-cols-12 tw-px-6 tw-items-center': $isScreenSizeAtLeast('m'),
    }"
  >
    <div class="md:tw-col-span-5">
      <!-- Retailer cover text -->
      <span v-if="retailerFooter" v-text="retailerFooter + '&nbsp;'" />

      <!-- Retailer legal text -->
      <span>
        By placing an order you are agreeing to the
        <a
          type="button"
          @click="gotToTermsLink()"
          style="text-decoration: underline; cursor: pointer"
          >Terms and Conditions</a
        >
        of {{ companyName }}.
      </span>
    </div>

    <div class="max-md:tw-pt-3 tw-text-[6px] md:tw-col-span-7 md:tw-text-right">
      <div>
        <span class="tw-leading-[0.5] tw-block md:tw-mr-2">Powered by</span>
        <IconShopthru />
      </div>

      <Copyright />
    </div>
  </footer>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useRetailerStore } from '@/stores/RetailerStore';
import { polyfill, elementScrollIntoView } from 'seamless-scroll-polyfill';
import Copyright from '@/components/Footer/Copyright/index.vue';
import IconShopthru from '@/components/icons/IconShopthruPoweredBy.vue';

export default {
  name: 'Footer',
  components: {
    IconShopthru,
    Copyright,
  },
  computed: {
    ...mapState(useRetailerStore, ['companyName', 'retailerFooter', 'termsUrl']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),
  },
  methods: {
    showTermsAndConditions() {
      polyfill();

      const element = document.getElementById('terms-tab');

      this.currentlyOpenInfoTab = 'TERMS_AND_CONDITIONS';

      if (element) {
        return elementScrollIntoView(element, { behavior: 'smooth' });
      }
    },

    gotToTermsLink() {
      window.open(this.termsUrl, '_blank');
    },
  },
};
</script>
